import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useGetQuestionsQuery } from 'api'
import { Vote } from 'features'

import { Question } from './Question'

const Questions = () => {
  const { data, error, isSuccess } = useGetQuestionsQuery()

  useEffect(() => {
    if (isSuccess) {
      document.getElementById(`question-${data?.at(-1)?.id}`)?.scrollIntoView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  if (error) return <div>Kunde inte hämta dina tidigare frågor</div>

  return (
    <Box
      display={'flex'}
      gap={5}
      mb={{ xs: 2, md: 5 }}
      overflow={'auto'}
      flexDirection={'column'}
      mt={'auto'}
    >
      {data?.map((question) => (
        <Box key={question.id}>
          <Vote relatedQuestionId={question.id} />
          <Question {...question} />
        </Box>
      ))}
    </Box>
  )
}

export default Questions
