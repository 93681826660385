import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { fullScreenHeaderCancelEventListener } from '@trr/app-shell-communication'
import { Questions, Ask, Welcome } from 'features'
import { useGetQuestionsQuery } from 'api'

const App = () => {
  const cancelFullScreen = () => (window.location.href = '/medarbetare')
  const { data, isLoading } = useGetQuestionsQuery()

  useEffect(() => {
    fullScreenHeaderCancelEventListener('add', cancelFullScreen)
    return () => {
      fullScreenHeaderCancelEventListener('remove', cancelFullScreen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        height={{ xs: 'calc(100dvh - 96px)', md: 'calc(100dvh - 124px)' }}
      >
        <CircularProgress size={48} sx={{ m: 'auto' }} />
      </Box>
    )
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
      flexDirection={'column'}
      height={{ xs: 'calc(100dvh - 96px)', md: 'calc(100dvh - 124px)' }}
    >
      {data?.length === 0 ? <Welcome /> : <Questions />}
      <Ask />
    </Box>
  )
}

export default App
