import {
  ContentCopy,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/material'
import { useGetQuestionsQuery, useVoteQuestionMutation } from 'api'
import React from 'react'
import { VoteStatus } from 'types'

const Vote = ({ relatedQuestionId }: { relatedQuestionId: string }) => {
  const [trigger] = useVoteQuestionMutation()

  const { data: questionData } = useGetQuestionsQuery()

  const currentQuestionVoteStatus = questionData?.find(
    (i) => i.id === relatedQuestionId
  ) ?? { isDownVoted: false, isUpVoted: false, voteReason: '' }

  const triggerVote = (args: VoteStatus) =>
    void trigger({ id: relatedQuestionId, ...args })

  const copyText = () => {
    const answer = document.getElementById(
      `answer-${relatedQuestionId}`
    ) as HTMLElement
    void navigator.clipboard.writeText(answer.innerText)
  }

  const svgHoverStyle = {
    svg: { color: 'text.secondary' },
    '&:hover svg': { color: 'primary.main' },
    '&.upVote svg': {
      color: currentQuestionVoteStatus.isUpVoted
        ? 'secondary.main'
        : 'text.secondary',
    },
    '&.downVote svg': {
      color: currentQuestionVoteStatus.isDownVoted
        ? 'error.main'
        : 'text.secondary',
    },
  }

  return (
    <Box display={'flex'} justifyContent={'end'}>
      <Stack
        borderRadius={3}
        display={'inline-flex'}
        bgcolor={'surface.grey'}
        direction={'row'}
        justifyContent={'end'}
      >
        <IconButton
          sx={svgHoverStyle}
          aria-label="Kopiera svaret"
          onClick={copyText}
        >
          <ContentCopy />
        </IconButton>
        <IconButton
          className={'upVote'}
          sx={svgHoverStyle}
          aria-label="Rösta upp svaret"
          onClick={() =>
            triggerVote({
              ...currentQuestionVoteStatus,
              isUpVoted: !currentQuestionVoteStatus?.isUpVoted,
              isDownVoted: false,
            })
          }
        >
          <ThumbUpOutlined />
        </IconButton>
        <IconButton
          className={'downVote'}
          sx={svgHoverStyle}
          aria-label="Rösta ned svaret"
          onClick={() =>
            triggerVote({
              ...currentQuestionVoteStatus,
              isDownVoted: !currentQuestionVoteStatus?.isDownVoted,
              isUpVoted: false,
            })
          }
        >
          <ThumbDownOutlined />
        </IconButton>
      </Stack>
    </Box>
  )
}

export default Vote
