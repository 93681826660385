import React from 'react'
import { Box, Typography } from '@mui/material'
import { getConfig } from 'utils'

const { MEDIA_URL } = getConfig()

const Welcome = () => {
  return (
    <Box
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      <Box mb={3} display={'flex'} justifyContent={'center'}>
        <img
          src={`${MEDIA_URL}/globalassets/common/welcome.png`}
          alt="Välkommen till ZendeskGPT"
        />
      </Box>
      <Box maxWidth={'80%'} mx={'auto'}>
        <Typography mb={3} textAlign={'center'} component={'h2'} variant={'h3'}>
          Välkommen till alpha testningen av TRRs egna AI-chattbot
        </Typography>
        <Typography textAlign={'center'} variant="body1">
          Tack för att ni vill hjälpa oss få fram bättre resultat. Ställ vilka
          frågor du vill om villkor, regler och andra saker du undrar som du
          behöver under din arbetsdag. Hjälp botten bli bättre genom att rösta
          upp eller ner dess svar.
        </Typography>
      </Box>
    </Box>
  )
}

export default Welcome
