import Send from '@mui/icons-material/Send'
import { CircularProgress, IconButton, TextField } from '@mui/material'
import { useAskQuestionMutation, useGetQuestionsQuery } from 'api'
import React, { useEffect, useState } from 'react'

const Ask = () => {
  const [questionInput, setQuestionInput] = useState('')
  const { data: questionData } = useGetQuestionsQuery()
  const [trigger, { data: askData, error, isLoading, isSuccess }] =
    useAskQuestionMutation()

  const handleAskQuestionClick = () => {
    void trigger({ questionText: questionInput })
  }

  useEffect(() => {
    if (isSuccess && askData) {
      setQuestionInput('')
      document.getElementById(`question-${askData.id}`)?.scrollIntoView()
    }
  }, [askData, isSuccess])

  return (
    <TextField
      value={questionInput}
      variant="outlined"
      error={!!error}
      onKeyDown={(e) => {
        e.key.toLowerCase() === 'enter' &&
          questionInput.length !== 0 &&
          handleAskQuestionClick()
      }}
      label={
        questionData?.length !== 0 ? 'Ställ en ny fråga' : 'Ställ en fråga'
      }
      autoComplete={'off'}
      helperText={error && 'Frågan kunde inte besvaras'}
      onChange={(e) => setQuestionInput(e.currentTarget.value)}
      InputProps={{
        endAdornment: isLoading ? (
          <CircularProgress />
        ) : (
          <IconButton
            aria-label="toggle password visibility"
            edge="end"
            onClick={handleAskQuestionClick}
            disabled={questionInput.length === 0}
          >
            <Send />
          </IconButton>
        ),
      }}
    />
  )
}

export default Ask
