import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import { GPTPatch, GPTRequest, GPTResponse } from 'types/gpt'
import { getConfig } from 'utils'

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (build) => ({
    getQuestions: build.query<GPTResponse[], void>({
      query: () => `/api/v1.0/zendesk-knowledgebase/Questions`,
    }),
    askQuestion: build.mutation<GPTResponse, GPTRequest>({
      query: (body) => ({
        url: `/api/v1.0/zendesk-knowledgebase/Questions`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_res, { dispatch, queryFulfilled }) => {
        await queryFulfilled
          .then(({ data }) =>
            dispatch(
              api.util.updateQueryData(
                'getQuestions',
                undefined,
                (stateQuestions) => {
                  stateQuestions.push(data)
                }
              )
            )
          )
          .catch(() => undefined)
      },
    }),
    voteQuestion: build.mutation<GPTResponse, GPTPatch>({
      query: (body) => ({
        url: `/api/v1.0/zendesk-knowledgebase/Questions/${body.id}`,
        method: 'PATCH',
        body: {
          isDownVoted: body.isDownVoted,
          isUpVoted: body.isUpVoted,
          voteReason: body.voteReason,
        },
      }),
      onQueryStarted: async (_res, { dispatch, queryFulfilled }) => {
        await queryFulfilled
          .then(({ data }) =>
            dispatch(
              api.util.updateQueryData(
                'getQuestions',
                undefined,
                (stateQuestions) => {
                  const index = stateQuestions.findIndex(
                    (i) => i.id === data.id
                  )
                  if (index !== -1) {
                    stateQuestions[index] = {
                      ...stateQuestions[index],
                      ...data,
                    }
                  }
                }
              )
            )
          )
          .catch(() => undefined)
      },
    }),
  }),
})

export const {
  useGetQuestionsQuery,
  useVoteQuestionMutation,
  useAskQuestionMutation,
} = api
export default api
