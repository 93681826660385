import { QuestionAnswerOutlined } from '@mui/icons-material'
import Help from '@mui/icons-material/Help'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { GPTResponse } from 'types'

const formatUrl = (urlString: string): string =>
  urlString.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')

const Question = ({ id, queryText, answer }: GPTResponse) => {
  return (
    <Card id={`question-${id}`} sx={{ borderRadius: 3, flex: '0 0 auto' }}>
      <CardHeader
        title={queryText}
        avatar={<Help />}
        titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
        sx={{ backgroundColor: 'primary.main', color: 'white' }}
      />
      <CardContent
        sx={{ display: 'flex', gap: 2, backgroundColor: 'surface.grey' }}
      >
        <QuestionAnswerOutlined />
        <Typography sx={{ wordBreak: 'break-word' }} id={`answer-${id}`}>
          {answer?.text}
        </Typography>
      </CardContent>
      {answer?.sources.length > 0 && (
        <>
          <Divider />
          <CardActions
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              px: 7,
              pb: 3,
              pt: 1.5,
              backgroundColor: 'surface.grey',
              gap: 1,
            }}
          >
            <Typography fontWeight="bold">Refererade artiklar:</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {answer?.sources.map((source) => (
                <Chip
                  key={source}
                  component="a"
                  label={formatUrl(source)}
                  href={source}
                  clickable
                  sx={{ maxWidth: '200px' }}
                />
              ))}
            </Stack>
          </CardActions>
        </>
      )}
    </Card>
  )
}

export default Question
